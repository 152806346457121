import React from "react"
import ImageMeta from "../../components/ImageMeta"

import TheaterVideo from "@components/Theater/TheaterVideo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

var classNames = require("classnames")

function Body(props) {
  var educationClasses = classNames({
    "body-section doc-bio": true,
    "text-first": props.post.educationResidency.textOrPhotoFirst === "text",
    "photo-first": props.post.educationResidency.textOrPhotoFirst === "photo"
  })

  var certificationClasses = classNames({
    "body-section doc-bio": true,
    "text-first": props.post.certifications.textOrPhotoFirst === "text",
    "photo-first": props.post.certifications.textOrPhotoFirst === "photo"
  })

  var hobbyClasses = classNames({
    "body-section doc-bio": true,
    "text-first": props.post.hobbiesInterests.textOrPhotoFirst === "text",
    "photo-first": props.post.hobbiesInterests.textOrPhotoFirst === "photo"
  })

  if (props.post.hasImages)
    return (
      <>
        <div
          className="body-section about-doc-block"
          style={{ paddingBottom: "100px", paddingTop: "72px" }}>
          <div className="columns">
            <div className="column is-2"></div>
            <MarkdownViewer
              className="column"
              markdown={props.post.aboutSection.body}
            />
            <div className="column is-2"></div>
          </div>
          {props.post.aboutSection.youtube && (
            <div className="columns">
              <div className="column has-text-centered">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${props.post.aboutSection.youtube}`}
                  controls={true}
                  playing={true}
                  overVideo={false}
                  onlyButton={true}></TheaterVideo>
              </div>
            </div>
          )}
        </div>

        <div className={educationClasses} style={{ paddingTop: "0" }}>
          <div className="columns is-vcentered">
            <div
              className={`column is-${props.post.educationResidency.columnWidths.left}`}></div>

            <MarkdownViewer
              className={`column first-text is-${props.post.educationResidency.textObject.width}`}
              markdown={props.post.educationResidency.textObject.body}
            />

            <div
              className={`column first-image is-${props.post.educationResidency.imageObject.width}`}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.post.educationResidency.imageObject.imageId}
                width="auto"
                responsive></ImageMeta>
            </div>

            <div
              className={`column is-${props.post.educationResidency.columnWidths.center}`}></div>

            <MarkdownViewer
              className={`column last-text is-${props.post.educationResidency.textObject.width}`}
              markdown={props.post.educationResidency.textObject.body}
            />

            <div
              className={`column last-image is-${props.post.educationResidency.imageObject.width}`}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.post.educationResidency.imageObject.imageId}
                width="auto"
                responsive></ImageMeta>
            </div>

            <div
              className={`column is-${props.post.educationResidency.columnWidths.right}`}></div>
          </div>
        </div>

        <div className={certificationClasses} style={{ paddingTop: "0" }}>
          <div className="columns is-vcentered">
            <div
              className={`column is-${props.post.certifications.columnWidths.left}`}></div>

            <MarkdownViewer
              className={`column first-text is-${props.post.certifications.textObject.width}`}
              markdown={props.post.certifications.textObject.body}
            />

            <div
              className={`column first-image is-${props.post.certifications.imageObject.width}`}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.post.certifications.imageObject.imageId}
                width="auto"
                responsive></ImageMeta>
            </div>

            <div
              className={`column is-${props.post.certifications.columnWidths.center}`}></div>

            <MarkdownViewer
              className={`column last-text is-${props.post.certifications.textObject.width}`}
              markdown={props.post.certifications.textObject.body}
            />

            <div
              className={`column last-image is-${props.post.certifications.imageObject.width}`}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.post.certifications.imageObject.imageId}
                width="auto"
                responsive></ImageMeta>
            </div>

            <div
              className={`column is-${props.post.certifications.columnWidths.right}`}></div>
          </div>
        </div>

        <div className={hobbyClasses} style={{ paddingTop: "0" }}>
          <div className="columns is-vcentered">
            <div
              className={`column is-${props.post.hobbiesInterests.columnWidths.left}`}></div>

            <MarkdownViewer
              className={`column first-text is-${props.post.hobbiesInterests.textObject.width}`}
              markdown={props.post.hobbiesInterests.textObject.body}
            />

            <div
              className={`column first-image is-${props.post.hobbiesInterests.imageObject.width}`}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.post.hobbiesInterests.imageObject.imageId}
                width="auto"
                responsive></ImageMeta>
            </div>

            <div
              className={`column is-${props.post.hobbiesInterests.columnWidths.center}`}></div>

            <MarkdownViewer
              className={`column last-text is-${props.post.hobbiesInterests.textObject.width}`}
              markdown={props.post.hobbiesInterests.textObject.body}
            />

            <div
              className={`column last-image is-${props.post.hobbiesInterests.imageObject.width}`}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.post.hobbiesInterests.imageObject.imageId}
                width="auto"
                responsive></ImageMeta>
            </div>

            <div
              className={`column is-${props.post.hobbiesInterests.columnWidths.right}`}></div>
          </div>
        </div>
      </>
    )

  return (
    <>
      <section className="body-section">
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column doctor-text-only-rows">
            <MarkdownViewer markdown={props.post.aboutSection.body} />
            <MarkdownViewer
              markdown={props.post.educationResidency.textObject.body}
            />
            <MarkdownViewer
              markdown={props.post.certifications.textObject.body}
            />
            <MarkdownViewer
              markdown={props.post.hobbiesInterests.textObject.body}
            />
          </div>
          <div className="column is-2"></div>
        </div>
      </section>
    </>
  )
}

export default Body
